import React, { FunctionComponent } from "react";

export interface AnchorElementInterface {
  fragmentId: string;
}

const AnchorElement: FunctionComponent<AnchorElementInterface> = ({ fragmentId }) => {
  return <div className="anchor-element" id={fragmentId} />;
};

export default AnchorElement;
